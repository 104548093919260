import $ from "jquery";
import moment from "moment";
// Services
import auth from "../services/auth";
import state from "../services/state";
import storageService from "../services/localstorage-util";
import subscriber from "models/subscriber";
import pianoFollowupCheck from "../support/piano/pianoFollowupCheck";

// modified date time format
var MODIFIED_DATE_TIME_FORMAT = "dd, DD MMM YYYY HH:mm:ss ZZ";

var USER_STATUS = {
	GUEST: "guest",
	REGISTERED: "registered",
	SUBSCRIBER: "subscribers"
};

// Default user state for guests
let userProfile = {
	id: null,
	guid: null,
	firstName: null,
	lastName: null,
	displayName: null,
	emailAddress: null,
	topics: [],
	newsletters: [],
	marketingCampaigns: [],
	viewed: [],
	quota: {
		limit: 4,
		count: 0,
		remaining: 4
	},
	ascendQuota: {
		limit: 3,
		count: 0,
		remaining: 3
	},	
	createdDate: null,
	lastUpdatedDate: null,
	lastVisitDate: null,
	subscriber: null,
	status: USER_STATUS.GUEST,
	state: "guest",
	industry: null,
	jobTitle: null,
	companySize: null,
	tiers: [],
	hmmEligible: false
};

// Resolved once the user has successfully logged in.
var userAuthDeferred = $.Deferred();

exports.setQuota = function (quota) {
	userProfile.quota = quota;
};

exports.setAscendQuota = function (quota) {
	userProfile.ascendQuota = quota;
};


/**
 * getStatus
 * Helper method used by exported methods
 * @returns {*}
 */
function getStatus() {
	return userProfile.status;
}

exports.getSubscriberType = function () {
	let roles = this.getTiers();
	if (roles.length > 0) {
		switch(roles[0]) {
		case "ROLE_HBRG_GOOD":
			return "Good"
		case "ROLE_HBRG_BETTER":
			return "Better"
		case "ROLE_HBRG_BEST":
			return "Best"
		case "ROLE_HBRG_ALLACCESS":
			return "All Access"
		}
	}
	return null;
};

exports.getSubscriberTypeCaption = function () {
	let roles = this.getTiers();
	if (roles.length > 0) {
		switch(roles[0]) {
		case "ROLE_HBRG_GOOD":
			return "Digital"
		case "ROLE_HBRG_BETTER":
			return "Digital + Print"
		case "ROLE_HBRG_BEST":
			return "Premium"
		case "ROLE_HBRG_ALLACCESS":
			return "All Access"
		}
	}
	return null;
};

exports.isRegistered = function () {
	return getStatus() === USER_STATUS.REGISTERED;
};

exports.isSubscriber = function () {
	return getStatus() === USER_STATUS.SUBSCRIBER;
};

exports.isGuest = function () {
	return getStatus() === USER_STATUS.GUEST;
};

exports.getId = function () {
	return userProfile.guid;
};

exports.getEmail = function () {
	return userProfile.emailAddress;
};

exports.getFirstName = function () {
	return userProfile.firstName;
};

exports.getLastName = function () {
	return userProfile.lastName;
};

exports.getFullName = function () {
	return userProfile.displayName;
};

exports.getQuotaLimit = function () {
	return userProfile.quota.limit;
};

exports.getQuotaProgress = function () {
	return userProfile.quota.count;
};

exports.getQuotaRemaining = function () {
	return userProfile.quota.remaining;
};

exports.isHmmEligible = function () {
	return userProfile.hmmEligible;
};

exports.getAscendQuota = function () {
	return userProfile.ascendQuota;
};

/**
 * getFollowingUnread
 * Returns the number of unread items in the users "following" queue
 */
exports.getFollowingUnread = function () {
	return 0;
};

exports.getFollowing = function () {
	return userProfile.topics.map(function (topic) {
		return topic.name;
	});
};

exports.getNewsletters = function () {
	return userProfile.newsletters.map(function (newsletter) {
		return newsletter.name;
	});
};

exports.getMarketingCampaigns = function () {
	return userProfile.marketingCampaigns.map(function (marketingCampaign) {
		return marketingCampaign.marketingCampaignId;
	});
};

exports.getSubscriberId = function () {
	if (userProfile.subscriber) {
		return userProfile.subscriber.digest;
	}
	return null;
};

exports.getTiers = function () {
	return userProfile.tiers.map(function (tiers) {
		return tiers.roleCode;
	});
};

exports.getIndustry = function() {
	if (userProfile.industry) {
		return userProfile.industry;
	}
	return null;
};

exports.getjobTitle = function() {
	if (userProfile.jobTitle) {
		return userProfile.jobTitle;
	}
	return null;
};

exports.getCompanySize = function() {
	if (userProfile.companySize) {
		return userProfile.companySize;
	}
	return null;
};

/**
 * Authenticate a user.
 *
 * @param {String} username - The username of the user.
 * @param {String} password - The password of the user.
 * @param {String} captchaToken - The captcha token for security.
 * @returns {object} A promise resolving when the login completes.
 */
exports.authenticate = function (username, password, captchaToken, deviceType="") {
	var dfd = $.Deferred();

	// Log in the user and fetch the user object.
	auth.login(username, password, captchaToken, deviceType)
		.then(function (response) {
			refreshUser().then(function () {
				dfd.resolve(response);
				$("body").trigger("sign-in:success");
			});
		}, function (response) {
			dfd.reject(response);
		});

	return dfd;
};

/**
 * Log out a user.
 *
 * @returns {object} A promise resolving when the logout completes.
 */
exports.logout = async function () {
	storageService.removeCache("user");
	storageService.removeCache("cart");
	storageService.removeCache("shopping-cart");

	// If there is a crumb cookie remove it while we are at it.
	if (pianoFollowupCheck.hasCrumb() == true) {
		pianoFollowupCheck.deleteCrumb();
	}

	await auth.logoutMsUser();
	auth.logoutRedirect();
};

/**
 * update - Updates the user
 * @param {string} attrs - data to update
 * @returns {promise.<object, Error>} - Promise with the updated user
 */
exports.update = function (attrs) {
	return auth.updateUser(attrs)
		.then(saveAndNotify);
};

exports.register = function (attrs) {
	var promise = $.Deferred();
	subscriber.fetch(attrs.emailAddress, null).then(function () {
		var encodedEmailAddress = btoa(attrs.emailAddress);
		window.location = "/subscriber-registration?emailAddress=" +
				encodeURIComponent(encodedEmailAddress);
	}, function () {
		auth.createNewUser(attrs).then(function (user) {
			user.id = user.guid;
			saveAndNotify(user);
			promise.resolve(user);
		}, function (res) {
			promise.reject(JSON.parse(res.responseText));
		});
	});
	return promise;
};

exports.pianoRegisterLegacy = function (attrs) {
	var promise = $.Deferred();
	subscriber.fetch(attrs.emailAddress, null).then(function () {
		console.log(arguments);
		var encodedEmailAddress = btoa(attrs.emailAddress);
		window.location = "/subscriber-registration?emailAddress=" +
				encodeURIComponent(encodedEmailAddress);
	}, function () {
		auth.createNewUserPiano(attrs).then(function ({user}) {
			user.id = user.guid;
			saveAndNotify(user);
			promise.resolve(user);
		}, function (res) {
			promise.reject(JSON.parse(res.responseText));
		});
	});
	return promise;
};

exports.paywallRegister = function (attrs) {
	var promise = $.Deferred();
	auth.createNewUser(attrs).then(function (user) {
		user.id = user.guid;
		saveAndNotify(user);
		promise.resolve(user);
	}, function (res) {
		promise.reject(res);
	});

	return promise;
};

exports.pianoRegister = function (attrs) {
	var promise = $.Deferred();
	auth.createNewUserPiano(attrs).then(function ({user, header}) {
		user.id = user.guid;
		saveAndNotify(user);
		promise.resolve({user, header});
	}, function (res) {
		promise.reject(res);
	});

	return promise;
};

/**
 * fetch
 * Fetches the user model from the server or the localStorage.
 * @returns {promise.<object, Error>} - A promise with the user
 *   profile attached.
 */
exports.fetch = refreshUser;

/**
 * getUpdatedUser
 * Fetches the user model from the server
 * @returns {promise.<object, Error>} - A promise with the user
 *   profile attached.
 */
function getUpdatedUser() {
	return auth.getUser().then(function (user) {
		return saveAndNotify(user);
	});
}

/**
 * fetchUpdated
 * Fetches the user model from the server.
 * @returns {promise.<object, Error>} - A promise with the user
 *   profile attached.
 */
exports.fetchUpdated = getUpdatedUser;

/**
 * refreshUser
 * Fetches the user model, either from the server or localStorage
 * @returns {promise.<object, Error>} - A promise with the user
 *   profile attached.
 */
function refreshUser() {
	// check local storage
	var localStorageUser = storageService.getCache("user");
	if (localStorageUser) {
		// return the promise from the modified query
		return auth.getUserModifiedTime().then(function (modifiedTime) {
			var userModified = moment(modifiedTime, MODIFIED_DATE_TIME_FORMAT);
			var savedProfileModified = moment(localStorageUser.lastModified,
				MODIFIED_DATE_TIME_FORMAT);

			// compare the dates, if the local storage one is good
			// return the local copy.
			if (savedProfileModified.isSame(userModified)) {
				return saveAndNotify(localStorageUser);
			} else {
				// get the user from the service
				return getUpdatedUser();
			}
		}, function () {
			// failed, remove the user from local storage
			storageService.removeCache("user");

			// return the default user profile
			return userProfile;
		});
	} else {
		// no cached user, get it from the server
		return getUpdatedUser();
	}
}

/**
 * saveAndNotify
 * @param {object} user - User Object to save and to resolve with
 * @returns {object} - Updated user object
 */
function saveAndNotify(user) {
	// copy the common properties
	userProfile.id = user.id;
	userProfile.guid = user.guid;
	userProfile.status = user.status;
	userProfile.firstName = user.firstName;
	userProfile.lastName = user.lastName;
	userProfile.emailAddress = user.emailAddress;
	userProfile.createdDate = new Date(user.createdDate);
	userProfile.lastUpdatedDate = new Date(user.lastUpdatedDate);
	userProfile.lastVisitDate = new Date(user.lastVisitDate);
	userProfile.quota = user.quota;
	userProfile.ascendQuota = user.ascendQuota;
	userProfile.displayName = user.displayName;
	userProfile.username = user.username;
	userProfile.subscriber = user.subscriber;
	userProfile.state = user.state;
	userProfile.industry = user.industry;
	userProfile.jobTitle = user.jobTitle;
	userProfile.companySize = user.companySize;
	userProfile.hmmEligible	= user.hmmEligible;
	userProfile.sptCustomAttributes	= user.sptCustomAttributes;

	// map over the topics, newsletters if they exist, they are optional
	if ($.isArray(user.topics)) {
		userProfile.topics = user.topics;
	}
	if ($.isArray(user.newsletters)) {
		userProfile.newsletters = user.newsletters;
	}
	if ($.isArray(user.marketingCampaigns)) {
		userProfile.marketingCampaigns = user.marketingCampaigns;
	}
	// map the tiers (roles)
	if ($.isArray(user.tiers)) {
		userProfile.tiers = user.tiers;
	}

	// save the user
	storageService.setCache("user", userProfile);

	// update the state
	state.set(userProfile.state);

	// resolve any login promise callbacks
	userAuthDeferred.resolve(userProfile);
	return userProfile;
}

exports.saveAndNotifyUser = saveAndNotify;

/**
 * Determine if a user is authenticated.
 *
 * @return {boolean} Whether or not the user is logged in.
 */
exports.isAuthenticated = function () {
	return userAuthDeferred.state() === "resolved";
};

exports.authPromise = userAuthDeferred.promise();
